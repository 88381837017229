<div class="header navbar navbar-expand-lg">
    <div class="logo">
        <a class="navbar-brand" href="#">DocShaala</a>
    </div>
</div>
<div *ngIf="registrationinProgress; else showSuccessMessage" class="d-flex justify-content-center">
    <div class="card signup-card m-4">
        <h3 class="card-header bg-primary text-white text-uppercase mb-0 text-color-blue text-center font-weight-500">
            Signup</h3>
        <div class="card-body">
            <form [formGroup]="signUpForm" (ngSubmit)="registerUser()">
                <div class="form-group">
                    <label>Name</label>
                    <input formControlName="name"
                        [ngClass]="(SignUpFormControls.name.touched && SignUpFormControls.name.errors) ? 'is-invalid': ((submitted && SignUpFormControls.name.errors ) ? 'is-invalid' : '')"
                        class="form-control" type="text">
                    <div
                        *ngIf="((submitted && SignUpFormControls.name.errors) || (SignUpFormControls.name.touched && SignUpFormControls.name.errors))">
                        <div class="text-danger" *ngIf="SignUpFormControls.name.errors.required">
                            Name is required
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Email</label>
                    <input class="form-control"
                        [ngClass]="(SignUpFormControls.email.touched && SignUpFormControls.email.errors ) ? 'is-invalid': ((submitted && SignUpFormControls.email.errors ) ? 'is-invalid' : '')"
                        formControlName="email" type="email">
                    <div
                        *ngIf="((submitted && SignUpFormControls.email.errors) || (SignUpFormControls.email.touched && SignUpFormControls.email.errors))">
                        <div class="text-danger" *ngIf="SignUpFormControls.email.errors.required">
                            Email is required
                        </div>
                        <div class='text-danger' *ngIf="SignUpFormControls.email.errors.pattern">
                            Email is invalid
                        </div>
                        <div class='text-danger' *ngIf="SignUpFormControls.email.errors.emailNotAvailable">
                            Email is already taken, please choose a different email.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input class="form-control"
                        [ngClass]="{ 'is-invalid': (SignUpFormControls.password.touched && SignUpFormControls.password.errors) || (submitted && SignUpFormControls.password.errors) }"
                        formControlName="password" type="password">
                    <div
                        *ngIf="(SignUpFormControls.password.touched && SignUpFormControls.password.errors) || (submitted && SignUpFormControls.password.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.password.errors.required">
                            Password is required</div>
                        <div class='text-danger' *ngIf="SignUpFormControls.password.errors.pattern">
                            Passwords must be at least 8 characters in length contain at least one lower-case
                            letter,
                            one upper-case letter, and one number.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input class="form-control"
                        [ngClass]="{ 'is-invalid': (SignUpFormControls.confirmPassword.touched && SignUpFormControls.confirmPassword.errors) || (submitted && SignUpFormControls.confirmPassword.errors) }"
                        formControlName="confirmPassword" type="password">
                    <div
                        *ngIf="(SignUpFormControls.confirmPassword.touched && SignUpFormControls.confirmPassword.errors) || (submitted && SignUpFormControls.confirmPassword.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.confirmPassword.errors.required">
                            Confirm Password is required</div>
                        <div class='text-danger' *ngIf="SignUpFormControls.confirmPassword.errors.mustMatch">
                            Password's must match</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Course</label>
                    <select formControlName="course" class="form-control">
                        <option value="">Choose Course</option>
                        <option value="mbbs">MBBS</option>
                        <option value="bds">BDS</option>
                    </select>
                    <div
                        *ngIf="(SignUpFormControls.course.touched && SignUpFormControls.course.errors) || (submitted && SignUpFormControls.course.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.course.errors.required">
                            Course is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Gender</label>
                    <select formControlName="gender" class="form-control">
                        <option value="">Choose Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others">Others</option>
                    </select>
                    <div
                        *ngIf="(SignUpFormControls.gender.touched && SignUpFormControls.gender.errors) || (submitted && SignUpFormControls.gender.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.gender.errors.required">
                            Gender is required</div>
                    </div>
                </div>
                <div class="form-group date-picker">
                    <label for="Choose Date of birth">Date of Birth</label>
                    <mat-form-field appearance="fill">
                        <div class="input-group mb-3">
                            <input (click)="picker.open()" matInput formControlName="dob"
                                (dateChange)="onDateChange($event)" [matDatepicker]="picker" class="form-control"
                                placeholder="DD/MM/YYYY" aria-label="Choose Date of birth"
                                aria-describedby="Choose Date">
                            <span class="input-group-text" id="Choose Date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </span>
                        </div>
                    </mat-form-field>
                    <div
                        *ngIf="(SignUpFormControls.dob.touched && SignUpFormControls.dob.errors) || (submitted && SignUpFormControls.dob.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.dob.errors.required">
                            Date of Birth is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Phone Number</label>
                    <input class="form-control" formControlName="phone" type="text">
                    <div
                        *ngIf="(SignUpFormControls.phone.touched && SignUpFormControls.phone.errors) || (submitted && SignUpFormControls.phone.errors)">
                        <div class='text-danger' *ngIf="SignUpFormControls.phone.errors.required">
                            Phone is required</div>
                        <div class='text-danger' *ngIf="SignUpFormControls.phone.errors.pattern">
                            Phone is required</div>
                    </div>
                </div>
                <div class="form-group text-center">
                    <button type="button" (click)="gotoLogin()" class="btn btn-outline-secondary">Cancel</button>
                    <button class="btn btn-primary ml-2">Signup</button>
                </div>
            </form>
        </div>
    </div>

</div>
<ng-template #showSuccessMessage>
    <div class="d-flex justify-content-center">
        <div class="card signup-card m-4">
            <h3 class="card-header text-success text-uppercase mb-0 text-color-blue text-center font-weight-500">
                Registration Success!</h3>
            <div class="card-body text-center">
                <p>Please
                    <a href="javascript:void()" (click)="gotoLogin()">click here</a> to login.
                </p>
            </div>
        </div>
    </div>
</ng-template>