import { Component, OnInit } from '@angular/core';
import { GlobalStateService } from './../../services/global-state.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  constructor(private globalState: GlobalStateService) { }

  ngOnInit(): void {
    this.globalState.currentLayout = 'MCQ';
  }

}
