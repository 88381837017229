import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from "jquery";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {
  currentLayout = '';
  showLoader = this.spinner;

  handleCartItems = new BehaviorSubject<string>('')
  // @ts-ignore: Object is possibly 'null'.
  showWarningAlert: boolean;
  // @ts-ignore: Object is possibly 'null'.
  warningClass: string;
  // @ts-ignore: Object is possibly 'null'.
  warningMessage: string;
  // @ts-ignore: Object is possibly 'null'.
  warningTitle: string;
  // @ts-ignore: Object is possibly 'null'.
  customAlert: string;
  // @ts-ignore: Object is possibly 'null'.
  customAlertClass: string;
  isNextLine: boolean | undefined;
  warningNextLine: string | undefined;
  constructor(private spinner: NgxSpinnerService) {
    const cartItem = sessionStorage.getItem('userLoginPendingCart') 
    if(cartItem){
      this.handleCartItems.next(cartItem);
    }
   }

  showMessage(type: string, message: string, title: string, timer?: boolean, isNextLine?: boolean, nextLineText?: string) {
    this.showWarningAlert = true;
    window.scroll(0, 0);
    if (type === 'success') {
      this.warningClass = 'alert-success';
      this.warningMessage = message;
      this.warningTitle = title;
      this.isNextLine = isNextLine;
      this.warningNextLine = nextLineText;
      timer = true;
    } else if (type === 'warning') {
      this.warningClass = 'alert-warning';
      this.warningMessage = message;
      this.warningTitle = title;
      this.isNextLine = isNextLine;
      this.warningNextLine = nextLineText;
      timer = true;
    } else if (type === 'warning-custom') {
      this.warningClass = 'alert-warning-custom';
      this.warningMessage = message;
      this.warningTitle = title;
      this.isNextLine = isNextLine;
      this.warningNextLine = nextLineText;
      timer = true;
    } else if (type === 'error') {
      this.warningClass = 'alert-danger';
      this.warningMessage = message;
      this.warningTitle = title;
      this.isNextLine = isNextLine;
      this.warningNextLine = nextLineText;
      timer = true;
    }
    
    if (timer) {
      setTimeout(() => { this.showWarningAlert = false; }, 5000);
    }
  }

  invokeGenericErrorMessage(customMessage?: string) {
    const message = customMessage !== undefined ? customMessage : 'Something went wrong, please try again!';
    this.showMessage('error', message, '');
    this.showLoader.hide();
  }

  errorWarnings() {
    const el = $('input.ng-invalid:not(form):first');
    const elm = $('select.ng-invalid:not(form):first');
    const editor = $('ckeditor.ng-invalid:not(form):first');
    if (el && el.offset()) {
      // @ts-ignore: Object is possibly 'null'.
      $('html,body').animate({ scrollTop: (el.length > 0 ? (el.offset().top - 100) : (elm.length > 0 ? (elm.offset().top - 100) : (editor.offset().top - 100))) }, 'slow', () => {
        if (el.length > 0) {
          el.focus();
        } else if (elm.length > 0) {
          elm.focus();
        }
      });
    }
  }

  decodeToken(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jsonToken = JSON.parse(jsonPayload);
    return jsonToken;
  }
}
