<div class="header navbar navbar-expand-lg">
    <div class="logo">
        <ng-container *ngIf="currentUserRole === 'admin'; else elseBlock">
          <a class="navbar-brand" routerLink="/ads/users/list" routerLinkActive="">DocShaala</a>
        </ng-container>
        <ng-template #elseBlock>
          <a class="navbar-brand" routerLink="/fs/mcq/list" routerLinkActive="">DocShaala</a>
        </ng-template>
      </div>
      
    <div class="d-flex justify-content-center align-items-center">
        <div class="mr-2 menu-nav">
            <ul class="navbar-nav">
                <ng-container *ngIf="currentUserRole == 'admin'">
                    <li>
                        <a class="nav-link" [routerLink]="['/ads/subject-list']"
                            [ngClass]="globalState.currentLayout == 'subject' ? 'active' : ''"
                            href="javascript:void(0)">Subjects</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/ads/unit-list']"
                            [ngClass]="globalState.currentLayout == 'unit' ? 'active' : ''"
                            href="javascript:void(0)">Units</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/ads/subunit-list']"
                            [ngClass]="globalState.currentLayout == 'subunit' ? 'active' : ''"
                            href="javascript:void(0)">Subunits</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/ads/users/list']"
                            [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                            href="javascript:void(0)">Users</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/ads/users/student-list']"
                            [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                            href="javascript:void(0)">Students</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="currentUserRole !== 'student' && currentUserRole !== 'admin'">
                    <li *ngIf="currentUserRole !== 'student'">
                        <a class="nav-link" [routerLink]="['/fs/mcq/list']"
                            [ngClass]="globalState.currentLayout == 'mcq' ? 'active' : ''"
                            href="javascript:void(0)">MCQ</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/fs/test/list']"
                            [ngClass]="globalState.currentLayout == 'test' ? 'active' : ''"
                            href="javascript:void(0)">Test
                            Series</a>
                    </li>
                    <li *ngIf="currentUserRole !== 'student'">
                        <a class="nav-link" [routerLink]="['/fs/note/list']"
                            [ngClass]="globalState.currentLayout == 'note' ? 'active' : ''"
                            href="javascript:void(0)">Notes</a>
                    </li>
                    <li>
                        <a class="nav-link" [routerLink]="['/fs/test-flag-errors']"
                            [ngClass]="globalState.currentLayout == 'flag' ? 'active' : ''"
                            href="javascript:void(0)">Test
                            Flag Errors</a>
                    </li>
                </ng-container>
                <li>
                    <a (click)="logout()" class="nav-link" href="javascript:void(0)">Logout</a>
                    <!-- <a *ngIf="isLoggedIn !== undefined" class="nav-link" href="javascript:void(0)"
                        [routerLink]="['/login']">Login</a> -->
                </li>
            </ul>
        </div>
        <div class="btn-group context-menu" dropdown placement="bottom right">
            <button id="button-basic" dropdownToggle type="button" class="btn context-menu-btn"
                aria-controls="dropdown-basic">
                <mat-icon class="text-white">more_vert</mat-icon>
            </button>
            <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right context-menu-content"
                role="menu" aria-labelledby="button-alignment">
                <ng-container *ngIf="currentUserRole == 'admin'">
                    <div class="context-menu-item">
                        <a [routerLink]="['/ads/subject-list']"
                            [ngClass]="globalState.currentLayout == 'subject' ? 'active' : ''"
                            href="javascript:void(0)">Subjects</a>
                    </div>
                    <div class="context-menu-item">
                        <a [routerLink]="['/ads/unit-list']"
                            [ngClass]="globalState.currentLayout == 'unit' ? 'active' : ''"
                            href="javascript:void(0)">Units</a>
                    </div>
                    <div class="context-menu-item">
                        <a [routerLink]="['/ads/subunit-list']"
                            [ngClass]="globalState.currentLayout == 'subunit' ? 'active' : ''"
                            href="javascript:void(0)">Subunits</a>
                    </div>
                    <div class="context-menu-item">
                        <a [routerLink]="['/ads/users/list']"
                            [ngClass]="globalState.currentLayout == 'account' ? 'active' : ''"
                            href="javascript:void(0)">Users</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="currentUserRole !== 'student' && currentUserRole !== 'admin'">
                    <div class="context-menu-item">
                        <a [ngClass]="globalState.currentLayout == 'mcq' ? 'active' : ''"
                            [routerLink]="['/fs/mcq/list']" href="javaScript:void(0);">MCQ</a>
                    </div>
                    <div class="context-menu-item">
                        <a [ngClass]="globalState.currentLayout == 'test' ? 'active' : ''"
                            [routerLink]="['/fs/test/list']" href="javaScript:void(0);">Test Series</a>
                    </div>
                    <div class="context-menu-item">
                        <a [ngClass]="globalState.currentLayout == 'note' ? 'active' : ''"
                            [routerLink]="['/fs/note/list']" href="javaScript:void(0);">Notes</a>
                    </div>
                    <div class="context-menu-item">
                        <a [ngClass]="globalState.currentLayout == 'flag' ? 'active' : ''"
                            [routerLink]="['/fs/test-flag-errors']" href="javaScript:void(0);">Test Flag Errors</a>
                    </div>
                </ng-container>
                <div class="context-menu-item">
                    <a (click)="logout()" href="javaScript:void(0);">Logout</a>
                </div>
            </div>
        </div>
    </div>
</div>