import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalStateService } from 'src/app/services/global-state.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from './../../services/validators/mustMatch.validator';
import { Router } from '@angular/router';
import { UserService } from './../../services/user.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  signUpForm!: UntypedFormGroup;
  submitted = false;
  emailNotAvailable = false;
  registrationinProgress = true;
  day!: number;
  month!: number;
  year!: number;

  constructor(private globalState: GlobalStateService,
    private router: Router,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy

  }

  ngOnInit(): void {
    this.signUpForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])],
      password: ['', Validators.compose([Validators.required, Validators.pattern(/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.pattern(/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{7,})\S$/)])],
      course: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      phone: ['', Validators.compose([Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/), Validators.required])]
    }, { validator: MustMatch('password', 'confirmPassword') });
  }

  get SignUpFormControls() {
    return this.signUpForm.controls;
  }

  onDateChange(selectedDate: any): void {
    const date = selectedDate.value;
    console.log('check date', date);
    console.log('check date', date.getDate());
    this.day = new Date(date).getDate();
    this.month = new Date(date).getMonth() + 1;
    this.year = new Date(date).getFullYear();
  }

  registerUser() {
    this.submitted = true;
    if (this.SignUpFormControls.email.errors && this.SignUpFormControls.email.errors.emailNotAvailable == true) {
      this.SignUpFormControls.email.setErrors([]);
    }
    if (this.signUpForm.invalid) {
      return;
    }
    this.globalState.showLoader.show();
    const registerReqBody = {
      name: this.signUpForm.value.name,
      email: this.signUpForm.value.email,
      password: this.signUpForm.value.password,
      course: this.signUpForm.value.course,
      phone: this.signUpForm.value.phone,
      gender: this.signUpForm.value.gender,
      dateOfBirth: {
        day: this.day,
        month: this.month,
        year: this.year
      }
    };
    this.userService.createUser(registerReqBody).subscribe(resp => {
      this.registrationinProgress = false;
      this.globalState.showLoader.hide();
    }, err => {
      if (err.error.error.indexOf('unique') > -1) {
        this.SignUpFormControls.email.setErrors({ emailNotAvailable: true });
        this.emailNotAvailable = true;
      } else {
        this.globalState.invokeGenericErrorMessage();
      }
    });
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }

}
