import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetStudentByIdResponseMondel } from '../models/createUserResponse.model';
import { UserLoginResponseModel } from '../models/studentLoginResponse.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  createUser(formData: any): Observable<UserLoginResponseModel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const httpOptions = {
      headers: headers
    };
    const bodyString = formData
    const url = environment.ApiBaseUrl.concat(`/students`);
    return this.http.post<UserLoginResponseModel>(url, bodyString, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getCurrentUser(id: any): Observable<GetStudentByIdResponseMondel[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students?account=${id}`);
    return this.http.get<GetStudentByIdResponseMondel[]>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

  getUserDetails(id: any): Observable<GetStudentByIdResponseMondel> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    });
    const httpOptions = {
      headers: headers
    };
    const url = environment.ApiBaseUrl.concat(`/students/${id}`);
    return this.http.get<GetStudentByIdResponseMondel>(url, httpOptions)
      .pipe(map((response) => {
        return response;
      }),
        catchError(error => this.handleError(error)));
  }

// 
  handleError(error: HttpErrorResponse) {
    let err: any;
    if (error.error.length >= 0) {
      err = error.error;
    } else {
      err = error;
    }
    return throwError(err);
  }
}
